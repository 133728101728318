import config from "@/config"
import { extractDisplayPurpose } from "../../lib/regex-tools"
import responseTexts from "../../lib/response-texts"
import { STATE_DONE } from "@/jscommon/src/store/payment/state-types"

export function thankYou (state, getters, rootState, rootGetters) {
  if (!state?.result?.data || ![STATE_DONE].includes(state.state)) {
    return {}
  }
  return {
    success: !!state.result?.successful,
    pending: ["pending", "hold"].includes(
      state.result?.data?.final_result_type
    ),
    amount: state.result?.data?.payment?.amount,
    purpose: extractDisplayPurpose(
      state.result?.data?.payment?.collection?.plan
    ),
    parishName:
      rootGetters["data/organizationLookupByUrl"][
        state.result?.data?.payment?.collection?.organization
      ]?.name,
    recurringPayment: state.result?.data?.recurring_payment ?? null,

    // FIXME These are oppwa specific
    resultCode: state.result?.data?.final_result_code,
    resultMessage:
      responseTexts[state.result?.data?.final_result_code] ??
      state.result?.data?.final_result_raw_message,
  }
}

export function configPaymentEnabled () {
  return {
    sepadd: config.payment.enabled.includes("DIRECTDEBIT_SEPA"),
    creditcard:
      config.payment.enabled.includes("VISA") ||
      config.payment.enabled.includes("MASTER"),
    giropay: config.payment.enabled.includes("GIROPAY"),
    googlepay: config.payment.enabled.includes("GOOGLEPAY"),
    paypal: config.payment.enabled.includes("PAYPAL"),
    wallee: config.payment.enabled.includes("WALLEE"),
  }
}

const RECURRING_ALLOWED = [
  { integration: "oppwa", method: "sepadd" },
  { integration: "oppwa", method: "creditcard" },
  { integration: "direct", method: "sepadd" },
]

export function enabledMethods (state, getters) {
  if (!state?.input?.paymentInformation?.length > 0) {
    return []
  }

  const methods = {}
  for (const val of state.input.paymentInformation) {
    for (const innerVal of val.integrations) {
      const innerMethods = innerVal?.available ?? [innerVal.integration]
      for (const method of innerMethods) {
        if (state.input.doRecurring) {
          if (
            !RECURRING_ALLOWED.some(
              (ra) =>
                ra.integration === innerVal.integration && ra.method === method
            )
          ) {
            continue
          }
        }

        if (innerVal.integration === "oppwa") {
          methods.oppwa = [...(methods?.oppwa ?? []), method].filter(
            (x) => getters.configPaymentEnabled[x]
          )
        } else if (innerVal.integration === "wallee") {
          methods.wallee = [...(methods?.wallee ?? []), method].filter(
            (x) => getters.configPaymentEnabled[x]
          )
        } else if (innerVal.integration === "paypal") {
          methods.paypal = [...(methods?.paypal ?? []), method]
        } else if (innerVal.integration === "direct") {
          methods.direct = [...(methods?.direct ?? []), method]
        } else if (innerVal.integration === "file") {
          methods.file = [...(methods?.file ?? []), method]
        }
      }
    }
  }

  const alreadyCovered = new Set()

  const retVal = Object.entries(methods).reduce((result, integration) => {
    return result.concat(
      integration[1]
        .filter((method) => !alreadyCovered.has(method))
        .map((method) => {
          alreadyCovered.add(method)
          return { integration: integration[0], method }
        })
    )
  }, [])
  retVal.sort(comparePaymentMethods)
  return retVal
}

/*
 * Reorder payment types.
 * Desired order: Lastschrift, Giropay, Kredikarte, Paypal
 * Unknown payment methods are pushed to the end of the list.
 */
function comparePaymentMethods (a, b) {
  const desiredOrder = [
    "sepadd",
    "giropay",
    "creditcard",
    "googlepay",
    "paypal",
  ]
  if (
    desiredOrder.indexOf(a.method) === -1 ||
    desiredOrder.indexOf(b.method) === -1
  ) {
    return 1
  }
  if (desiredOrder.indexOf(a.method) < desiredOrder.indexOf(b.method)) {
    return -1
  } else {
    return 1
  }
}
