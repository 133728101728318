import { render, staticRenderFns } from "./PaymentFragmentWallee.vue?vue&type=template&id=cff6e83c&scoped=true&"
import script from "./PaymentFragmentWallee.vue?vue&type=script&lang=js&"
export * from "./PaymentFragmentWallee.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff6e83c",
  null
  
)

export default component.exports